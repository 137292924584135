
export default {
    data() {
        const SS_URL = process.env.SS_URL;
        return {
            language: this.$store.state.user.language,
            accessibe: this.$store.state.user.accessibe,
            blockly: this.$store.state.localStorage.blockly,
            skillmoji: SS_URL + "/account/avatar",
            password: SS_URL + "/account/reset.password",
            changed: false,
            options: [
                { text: "English", value: "en" },
                { text: "Español", value: "es" }
            ]
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        version() {
            return process.env.VERSION;
        },
        codeOptions() {
            if (this.user.section) {
                return this.user.section.blocks
                    ? [{ text: this.$tf("Blocks (drag & drop)"), value: "true" }]
                    : [{ text: this.$tf("Text (typing)"), value: "false" }];
            }
            return [
                { text: this.$tf("Blocks (drag & drop)"), value: "true" },
                { text: this.$tf("Text (typing)"), value: "false" }
            ];
        }
    },
    watch: {
        language() {
            this.changed = true;
        },
        accessibe() {
            this.changed = true;
        },
        blockly() {
            this.changed = true;
        }
    },
    methods: {
        async save() {
            this.changed = false;
            const el = this.$refs.saveButton;
            el.disabled = true;
            el.innerHTML = this.$tf("Saving...");
            if (this.blockly !== this.$store.state.localStorage.blockly) {
                this.$store.commit("localStorage/blockly", this.blockly == "true");
            }
            if (
                this.user.onlyCode ||
                this.user.demo ||
                (this.language === this.$store.state.user.language &&
                    this.accessibe === this.$store.state.user.accessibe)
            ) {
                el.innerHTML = this.$tf("Saved!");
                setTimeout(() => {
                    el.innerHTML = this.$tf("Save");
                    el.disabled = false;
                }, 3000);
                return false;
            }
            this.$axios
                .$put("/account", { language: this.language, accessibe: this.accessibe })
                .then(() => {
                    const user = { ...this.$store.state.user };
                    user.language = this.language;
                    user.accessibe = this.accessibe;
                    this.$i18n.setLocale(user.language);
                    window.history.replaceState("", "", this.switchLocalePath(user.language));
                    this.$store.commit("user", user);
                    el.innerHTML = this.$tf("Saved!");
                    this.$nuxt.$emit("profileUpdated");
                    this.$nuxt.$emit("switchLang", this.language)
                    window.location.reload();
                })
                .catch(err => {
                    console.error(err);
                    alert(this.$tf("An error occurred while saving account settings"));
                })
                .then(() => {
                    setTimeout(() => {
                        el.innerHTML = this.$tf("Save");
                        el.disabled = false;
                    }, 3000);
                });
        },
        refresh() {
            window.location.href = `${process.env.LOGIN_URL}?next=${this.$route.path}`;
        },
        logout() {
            window.location.href = process.env.LOGOUT_URL;
        },
        linkAccount(kind) {
            const url = `${process.env.SS_URL}/account/${kind}.link`;
            window.open(url, "_target");
        },
        onBeforeUnload() {
            if (this.changed) {
                return "You have unsaved changes";
            }
        }
    },
    mounted() {
        window.onbeforeunload = this.onBeforeUnload;
    },
    beforeDestroy() {
        window.onbeforeunload = undefined;
    }
};
